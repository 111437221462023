.login-container {
  height: 380px;
  margin-top: 38px;
  justify-content: center;
  align-items: center;
  .input-element {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 220px;
    margin: 10px 0;
    label {
      margin-left: 12px;
      font-size: 16px;
      margin-top: 8px;
    }
  }
  input[type="password"], input[type="text"] {
    background-color: #F1F1F1;
    border-color: #e7e7e9;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 10px 16px;
    outline: none;
    border-radius: 8px;
    transition: background-color 200ms ease, outline 200ms ease, color 200ms ease, box-shadow 200ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    cursor: text;
    margin: 0em;
    margin-top: 0.5rem;
  }
  #login {
    -webkit-appearance: none;
    background-color: $yellow;
    font-size: 17px;
    border-radius: 5px;
    font-family: "Ubuntu Mono";
    font-weight: bold;
    font-size: 24px;
    border: none;
    padding: 14px;
    width: 100%;
    margin-top: 30px;
  }
  #login:hover {cursor: pointer;}
}

.login-title {
  max-width: 250px;
  text-align: right;
  font-size: 2.7rem;
  font-family: "Ubuntu Mono";
  text-align: center;
  font-weight: 700;
  span {
    color: $yellow;
  }
}
