.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;

  h1,
  h3 {
    font-family: "Ubuntu Mono", monospace;
  }

  h1 {
    font-size: 2rem;
    color: white;
    letter-spacing: 0.14rem;
    margin-bottom: 32px;
    text-align: center;
  }

  .faq-list {
    max-width: 900px;
    margin: 0 29px;

    h3 {
      margin-bottom: 8px;
    }

    .faq-answer {
      margin-bottom: 24px;
      line-height: 1.4;
      text-align: justify;
      opacity: 0.6;
    }
  }
}
