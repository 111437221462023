.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    width: 90%;
    text-align: center;
    margin-bottom: 48px;
    font-family: "Ubuntu Mono";
    font-size: 2.7rem;
  }
  
  .contact-button {
    background-color: $yellow;
    color: black;
    text-decoration: none;
    padding: 14px 42px;
    font-size: 1.4rem;
    font-family: "Ubuntu Mono";
    font-weight: 700;
    border-radius: 4px;
    border: none;
  }
}
