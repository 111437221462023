.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .particles-container {
    z-index: -100;
    opacity: 0.1;
    position: absolute;
    canvas {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .home-flex {
	  display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 120px;
  }
  .item {
    flex: 1 1 0;
    width: 100%;
    max-width: 500px;
    height: 50vh;
    padding: 10px;
    margin: 20px;
  }

  .item-description {
    h1 {
      font-size: 60px;
      text-align: center;
      font-family: "Ubuntu Mono", monospace;
      min-height: 180px;
    }
    .description-text {
      margin: 48px auto;
      font-size: 18px;
      text-align: justify;
      opacity: 0.6;
      line-height: 1.8;
      max-width: 400px;
    }
  }
  .item-youtubers {
    padding: 40px;

    .youtuber-avatar-container {
      position: relative;
      display:inline-block;
    }
    .tooltip-content {
      position: absolute;
      z-index: 10;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      background: $grey;
      padding: 10px 20px;
      border-radius: 8px;
      h1 {
        font-size: 22px;
        font-family: "Ubuntu";
      }
      .open-youtuber-page-bttn {
        display: inline-block;
        background-color: $blue;
        padding: 8px 16px;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        margin-top: 10px;
        font-family: "Ubuntu Mono", monospace;
        font-weight: bold;
        font-size: 16px;
        &:hover {
          transform: scale(1.05);
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent $grey transparent;
      }
    }
    .youtuber-avatar-container .tooltip-content{
      visibility: hidden;
    }
    .youtuber-avatar-container:hover .tooltip-content{
      visibility: visible;
    }
    img {
      width: 88px;
      margin: 5px;
      border-radius: 50%;
    }
    .youtuber-avatar-container:nth-child(1) {
      margin-left: 50px;
    }
    .youtuber-avatar-container:nth-child(8) {
      margin-left: 50px;
    }
  }
  .and-many-more {
    padding-top: 20px;
    font-family: "Ubuntu Mono";
    font-size: 18px;
    opacity: 0.6;
  }

  @media only screen and (max-width: 1100px) {
    .item-description h1 {
      font-size: 50px;
      .description-text {
        font-size: 16px;
      }
    }
    .item-youtubers {
      padding: 70px;
      padding-top: 45px;

      img {
        width: 77px;
        border-radius: 50%;
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    .item-youtubers {
      display: none;
    }
    .item {
      max-width: 850px;
    }
  }
  @media only screen and (max-width: 450px) {
    .item-description h1 {
      font-size: 40px;
      min-height: 0;
      .description-text {
        font-size: 13px !important;
      }
    }
  }
  // SEE RANKING BUTTON
  .see-ranking-bttn-containter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    a {
      background-color: $yellow;
      color: black;
      text-decoration: none;
      padding: 14px 42px;
      font-size: 1.4rem;
      font-family: "Ubuntu Mono";
      font-weight: 700;
      border-radius: 4px;
      border: none;
      &:hover {
        transform: scale(1.07);
      }
    }
  }
}

// MEDIA QUERIES
// make content on desktop move a bit more up
@media (min-width: 600px) {
  .home-container {
    padding-bottom: 10vh;
  }
}
@media (max-width: 350px) {
  .home-flex {
    margin-top: 0px !important;
    .item-description > h1 {
      font-size: 32px;
    }
    .description-text {
      font-size: 16px !important;
    }
  }
}
