// BASIC PAGE CSS
.ranking-container {
  // width: calc(min(100vw, 1020px));
  max-width: 1020px;
  margin: 0 auto;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title {
    font-size: 2rem;
    font-family: "Ubuntu Mono";
    text-align: center;
    font-weight: 700;
    margin-top: 48px;
    margin-bottom: 26px;
  }
  .subtitle {
    margin-bottom: 20px;
    opacity: 0.6;
    margin-left: 10px;
    margin-right: 10px;
  }
}

// RANING TABLE CSS
table.ranking-table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  // border-collapse: collapse;

  // col width behaviour
  // fixed for some columns, the rest grows automatically
  colgroup {
    col.\# { width: 40px; }
    col.who { width: 60px; }
    col.coins-called { width: 110px; }
    col.posted { width: 150px; }
    col.roi-btc,
    col.roi-usd {
      width: 120px;
    }
  }
  // MEDIA QUERIES
  @media (max-width: 850px) {
    colgroup col.roi-btc,
    colgroup col.roi-usd {
      width: 80px;
    }
  }
  @media (max-width: 720px) { .roi-usd { display: none; }}
  @media (max-width: 610px) { .posted { display: none; }}
  @media (max-width: 500px) { .coins-called { display: none; }}
  // MEDIA QUERIES for mobile (make content smaller and make roi badges hoverstate by default)
  @media (max-width: 500px) {
    col.\# {
      max-width: 30px !important;
      width: 30px !important;
    }
    col.who {
      width: 50px !important;
    }
    tbody td img.avatar {
      width: 43px !important;
    }
    tbody td.video {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }
    tbody td.video .video-title {
      font-size: 12px !important;
    }
    tbody td.video .channel-name {
      font-size: 16px !important;
    }
  }

  // sticky header styling
  thead {
    background-color: $yellow;
    color: black;
    th {
      position: sticky;
      background: $yellow;
      color:black;
      top: 72px; // = height of the navbar
      padding: 8px 0;
      z-index: 5;
    }
  }
  // header cell with sorting triangles
  // TRIANGLE
  thead th .arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0,0,0,0.25);
  }
  thead th .arrow-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0,0,0,0.25);
    margin-top:1.5px;
  }
  thead th .th-cell-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  thead th .arrows-container {
    margin-left: 3px;
    &.disabled {
      visibility: collapse;
    }
    &.asc {
      .arrow-up {
        border-bottom: 6px solid black;
      }
      .arrow-down {
        border-top: 6px solid transparent;
      }
    }
    &.desc {
      .arrow-up {
        border-bottom: 6px solid transparent;
      }
      .arrow-down {
        border-top: 6px solid black;
      }
    }
  }

  // text align center by default for all cells
  thead th,
  tbody td {
    text-align: center;
  }

  // basic table body row styling
  tbody td {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  }

  // styling for specific cells
  // video
  thead th.video,
  tbody td.video {
    text-align: left;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  // who
  thead th.who,
  tbody td.who {
    padding-left: 0;
    padding-right: 0;
  }
  tbody td.who img.avatar {
    width: 55px;
    border-radius: 50%;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  // coins called
  tbody td.coins-called {
    font-size: 12px;
  }
  // posted
  tbody td.posted {
    font-size: 16px;
  }
  // video
  tbody td.video {
    p.channel-name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    p.video-title {
      font-size: 14px;
    }
  }
  // ROI badge styling
  tbody td.roi {
    .roi-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 8px;
      margin-left: 5px;
      margin-right: 5px;
      &.red {
        font-weight: bold;
        color:white;
        color: $red;
        background-color: #1d1717; // not sure which color is nicer
        background-color: #362424;
      }
      &.green {
        font-weight: bold;
        color:white;
        color: $green;
        background-color: #253020; // not sure which color is nicer
        background-color: #2f3f27;
      }
    }
    .percentage-text {
      font-size: 14px;
    }
    // info-badge inside the roi-badge
    .circle-icon-info {
      height: 20px;
      margin-left: 5px;
      padding: 1px 3px;
      border-radius: 5px;
      z-index: 1;
      &.red {
        background-color: $red-opacity;
      }
      &.green {
        background-color: $green-opacity;
      }
      img {
        height: 20px;
        width: 20px;
      }
      p {
        font-family: "Ubuntu Mono";
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        color: white;
        opacity: 0.7;
      }
    }
    // tooltip when hovering over roi badges
    .roi-tooltip {
      position: absolute;
      bottom: -23px;
      display: none;
      border-radius: 8px;
      z-index: 5;
      width: 280px;
      font-family: "Ubuntu";
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      padding: 12px 12px;
      color: white !important;
      background-color:$grey !important;
      * {
        color: white !important;
        background-color: $grey !important;
      }
    }
    .roi-tooltip.first {
      left: 240px;
    }
    .roi-tooltip.second {
      left: 120px;
    }
    .roi-tooltip .currency-value {
      font-weight: bold;
      font-size: 18px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .roi-tooltip .currency-value .green {
      color: $green !important; // @TODO: does not work, cleanup classes
    }
    .roi-tooltip .currency-value .red {
      color: $red !important; // @TODO: does not work, cleanup classes
    }
    .roi-tooltip .disclaimer {
      font-size: 13px;
      line-height: 13px;
      margin-top: 10px;
    }
  }

  // hover state
  tbody tr:hover * {
    background-color: $grey;
    color: white;
  }
  tbody tr:hover .roi-badge {
    padding: 8px;
  }
  tbody tr:hover .roi-badge.green,
  tbody tr:hover .roi-badge.green * {
    color: white;
    background-color: $green;
  }
  tbody tr:hover .roi-badge.red,
  tbody tr:hover .roi-badge.red *  {
    color: white;
    background-color: $red;
  }
  tbody .roi-badge:hover .circle-icon-info p {
    opacity: 1;
  }
  tbody .roi-badge:hover .roi-tooltip {
    display: inline;
  }
}

/*
 * Spinner animation for loading
 * source https://tobiasahlin.com/spinkit/
 */
.spinner {
  margin: 0 auto 0;
  margin-top: 20px;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $yellow;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


// SEE ALL VIDEOS BUTTON IN THE BOTTOM,
// only shows when a keyword is selected
.see-all-videos-bttn {
  margin-top: 40px;
  background-color: $yellow;
  color: black;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Ubuntu Mono", monospace;
  padding: 8px 16px;
  border-radius: 8px;

  &:hover {
    transform: scale(1.05);
  }
}


tr.weekly-digest-add {
  img.letter-emoji {
    width: 50px;
  }
  // background-color: $yellow;
  border-radius: 20px;
  color: black;
  height: 85px;
  td:first-child {
    border-radius: 10px 0 0 10px;
  }
  td:last-child {
    border-radius: 0 10px 10px 0;
  }
  td {
    background-color: $yellow;
  }
}


// .email-input > input {
//   :hover {
//     background-color: white !important;
//   }
// }

tr.weekly-digest-add:hover * {
  background-color: $yellow !important;
  color: black !important;
}
tr.weekly-digest-add:hover input {
  background-color: white !important;
  color: black !important;
}

tr.weekly-digest-add .emoji {
  margin-left: 20px;
}

tr.weekly-digest-add input {
  width: 220px;
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid grey;
  margin-left: 20px;
  font-size: 14px;
}

tr.weekly-digest-add td.add-content .text {
  h1 {
    font-size: 22px;
    margin-bottom: 5px;
  }
  p {
    font-size: 13px;
    opacity: 0.7;
  }
}

.submit-email-bttn {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
tr.weekly-digest-add:hover .submit-email-bttn {
  background-color: black !important;
  color: white !important;
}
.emoji > h1 {
  bottom: 0;
  position: relative;
  animation-name: wobble;
  animation-duration: .9s;
  animation-iteration-count: infinite;
}

// WOBBLE EFFECT FOR FINGER EMOJI
@keyframes wobble {
  from {right: -10px;}
  to {right: 0px;}
}


.video-title-and-button-section {
  display: flex;
  align-items: center;
  .watch-video {
    font-size: 14px;
    background-color: none;
    color: #868aff;
    border-radius: 12px;
    padding: 2px 10px;
    margin-left: 20px;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}
tbody tr:hover .watch-video {
  background-color: none;
  color: #868aff !important;
}
