
.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  margin-top: 20px !important;
}

.shillcoin-bttn {
  background-color: #ffe91c;
  text-decoration: none;
  padding: 14px 42px;
  font-size: 1.4rem;
  font-family: "Ubuntu Mono";
  font-weight: 700;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  a {
    color: black !important;
    font-family: "Ubuntu Mono" !important;
    text-decoration: none !important;
  }
  &:hover {
    transform: scale(1.05);
  }
}
