* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  height: 100%;
}

body {
  background-color: $background-main;
  color: white;
  font-family: "Ubuntu", sans-serif;
}

.main {
  .main-content {
    display: flex;
    flex-direction: column;
  }
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}


.expand-content-to-full-height {
  min-height: calc(100vh - 140px);
}

// Basic Styling
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.pointer {
  cursor: pointer;
}
.remove-all-link-styling {
  outline: 0;
  text-decoration: none;
  color: white;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.text-elipsis { // wrap text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// remove selection and borders on click
// https://stackoverflow.com/a/21003770/6272061
.no-select {
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.yellow-text {
  color: $yellow;
}
